.fnav {
    padding: 75px 0 40px;
    background-color: #fff;

    &--inner {
        @include mq('tablet') {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @include mq('pc') {
            display: flex;
            justify-content: space-between;
        }
    }

    &--unit {
        &:not(:first-of-type) {
            margin-top: 30px;
        }
        
        @include mq('tablet') {
            width: 190px;
            margin: 30px 10px 0 10px;
        }
        
        @include mq('pc') {
            width: 190px;
            margin-top: 30px;
        }
    }

    &--tit {
        padding-bottom: 0.3em;
        font-size: 1.8rem;
        font-weight: normal;
        border-bottom: 1px solid #707070;
    }

    &--list {
        margin-top: 10px;
    }

    &--item {
        position: relative;
        padding-left: 1em;
        font-size: 1.4rem;
        line-height: 2;

        &::before {
            @include arrow('right', 6px, 2px, $color-key);
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
    }
}