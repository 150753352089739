.tabs {
    $this: &;
    display: flex;
    justify-content: space-between;

    &--item {
        margin-top: 15px;

        @include mq('pc') {
            width: 25%;
            margin-top: 20px;
        }

        a {
            display: inline-block;
            padding: 5px 0.5em;
            font-size: 1.3rem;
            text-align: center;
            color: $color-text-thin;
            border-bottom: 4px solid transparent;
            transition: all 0.2s ease;
    
            @include mq('pc') {
                display: block;
                padding: 15px 0;
                font-size: 2.6rem;
                border-bottom-width: 6px;
            }
        }
        
        &.is_active {
            a {
                color: $color-text-base;
                border-color: #555;
            }
        }
    }

    &.is_character {
        justify-content: flex-start;

        #{$this}--item {
            @include mq('sp') {
                margin-right: 20px;
            }
            
            a {
                @include mq('sp') {
                    font-size: 1.5rem;
                }
            }
        }
    }
}