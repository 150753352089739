.main {
    display: block;
    margin-top: map-get($headerH, 'sp');
    
    @include mq('tablet') {
        margin-top: map-get($headerH, 'tab');
    }

    @include mq('pc') {
        margin-top: map-get($headerH, 'pc');
    }
}