.conts {
    position: relative;
    display: none;

    @include mq('sp') {
        background-color: #f4f4f4;
    }

    @include mq('pc') {
        background: linear-gradient(to right, rgba(#FDB15E, 0.46), #F8F8F8),
                    linear-gradient(to right, #F8F8F8, #F8F8F8),
                    linear-gradient(to left, rgba(#FDB15E, 0.46), #F8F8F8);
        background-repeat: no-repeat;
        background-size: 170px 100%, calc(100% - (170px * 2)) 100%, 170px 100%;
        background-position: 0 0, 170px 0, 100% 0;
    }

    &.is_show{
        display: block;
    }

    &--narrow {
        white-space: nowrap;
        overflow-x: auto;
        padding: 20px 0;

        @include mq('pc') {
            padding: 30px 0 0 0;
        }
    }

    &--narrow_item {
        display: inline-block;
        
        a {
            display: block;
            height: 31px;
            padding: 0 1em;
            margin-right: 10px;
            font-size: 1.5rem;
            line-height: 31px;
            color: $color-key02;
            background-color: #fff;
            border: 1px solid $color-key02;
            border-radius: 20px;
            transition: all 0.2s ease;
            
            @include mq('pc') {
                height: 40px;
                font-size: 2rem;
                line-height: 40px;
                margin-right: 40px;
            }
        }

        // &.is_login {
        //     a {
        //         color: #fff;
        //         background-color: $color-login;
        //         border-color: $color-login;
        //     }
        // }

        // &.is_beginner {
        //     a {
        //         color: #fff;
        //         background-color: $color-beginner;
        //         border-color: $color-beginner;
        //     }
        // }
        
        &.is_active {
            a {
                color: #fff;
                background-color: $color-key02;
                border-color: $color-key02;
            }
        }
    }

    &--display {
        padding: 10px 0;
        text-align: right;
        background-color: #fff;

        @include mq('pc') {
            display: none;
        }
    }

    &--display_item {
        display: inline-block;
        margin-right: 20px;
        
        a {
            position: relative;
            display: block;
            width: 23px;
            height: 23px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right : 0;
                bottom : 0;
                left: 0;
                margin: auto;
                transition: all 0.2s ease;
            }
        }

        &.is_active {
            a {
                pointer-events: none;

                &:hover {
                    opacity: 1;
                }
            }
        }

        $display: 'grid', 'detail';

        @each $icon in $display {
            &.-#{$icon} {
                a {
                    &::before {
                        background: url('../img/common/icon_#{$icon}.svg') no-repeat border-box center/100% auto;
                    }
                }
            }

            &.-#{$icon}.is_active {
                a {
                    &::before {
                        background: url('../img/common/icon_#{$icon}_active.svg') no-repeat border-box center/100% auto;
                    }
                }
            }
        }
    }

    &--more {
        @include arrow('bottom', null, 6px, #808080);
        position: absolute;
        right: 0;
        bottom: 150px;
        left: 0;
        display: block;
        margin: auto;
        
        &.is_full {
            @include arrow('top', null, 6px, #808080);
        }
    }
}