.login {  
    position: relative;
    display: inline-block;
    height: 45px;
    padding: 0 20px 0 60px;
    margin-left: auto;
    line-height: 45px;
    color: #4026AD;
    border: 1px solid #4026AD;
    border-radius: 4px;

    @include mq('pc') {
        padding-right: 32px;
    }

    img {
        position: absolute;
        top: 0;
        bottom : 0;
        left: 15px;
        margin: auto;
    }
}