// =====================================================================================
//
// ミックスイン
//
// =====================================================================================

// -------------------------------------------------------------------------------------
// @mediaを出力
// -------------------------------------------------------------------------------------
@mixin mq( $media-type ){
  @media #{map-get( $media-queries, $media-type )}{
    @content;
  }
}

// -------------------------------------------------------------------------------------
// 矢印
// -------------------------------------------------------------------------------------
@mixin arrow($type: 'right', $size: null, $weight: 2px, $color: color('base'), $round: false) {
  // $type: 上下左右 'top', 'right', 'bottom', 'left' 
  // $size: 長さ font-sizeで調整
  // $weight: 太さ
  // $color: 色
  // $round: 端を丸める 使用する場合true

  width: 1em;  
  height: 1em;
  font-size: $size;
  border-style: none;
  
  @if ($round == false) {
      border-top-style: solid;
      border-left-style: solid;
      border-width: $weight;
      border-color: $color;
  }
  
  @else {
      &::before, &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-color: $color;
          border-radius: ($weight / 2);
      }

      &::before {
          width: $weight;
          height: 100%;
      }

      &::after {
          width: 100%;
          height: $weight;
      }     
  }

  @if ($type == 'top') {
      transform: rotate(45deg);
  }

  @if ($type == 'right') {
      transform: rotate(135deg);
  }

  @if ($type == 'bottom') {
      transform: rotate(-135deg);
  }

  @if ($type == 'left') {       
      transform: rotate(-45deg);    
  }
}

// -------------------------------------------------------------------------------------
// flexカラムを均等に並べる
// -------------------------------------------------------------------------------------
@mixin flex-column($column: 3, $multi: true, $marginX: 20px, $marginY: 20px) {
    // $column: カラム数
    // $multi: 複数行かどうか
    // $marginX: 横の間隔
    // $marginY: 縦の間隔

    width: calc(#{(100% / $column)} - #{$marginX} * #{($column - 1)} / #{$column});
    margin-right: $marginX;

    &:nth-of-type(#{$column}n) {
        margin-right: 0;
    }

    @if ($multi == true) {
        &:nth-of-type(n+#{($column + 1)}) {
            margin-top: $marginY;
        }
    }
}

// -------------------------------------------------------------------------------------
// 任意の親要素を挿入
// -------------------------------------------------------------------------------------
@mixin insert-context ($target, $state: null) {
    // $target: 挿入したい親要素
    // $state: 付与したい状態(.is-active, :hoverなど)
    // ex. @include insert-context('#{$this}__row', ':nth-of-type(odd)') {}

    $selector: #{&};
    $array: '';
    $space: if(str-index($selector, ' '), str-index($selector, ' '), 0);
    
    //直前の親要素を取得
    @while $space > 0 {
        $selector: str-insert($selector, ',', $space);
        $array: selector-parse($selector);
        $selector: #{nth($array, length($array))};
        $space: if(str-index($selector, ' '), str-index($selector, ' '), 0);
    }

    $parent: $selector;

    @if ($state) {
        @at-root #{selector-replace(&, $parent, $target + $state + ' ' + $parent)} {
            @content;
        }
    } @else {
        @at-root #{selector-replace(&, $parent, $target + ' ' + $parent)} {
            @content;
        }
    }
}