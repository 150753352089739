// =====================================================================================
//
// character_head
//
// =====================================================================================

.character_head {
    $this: &;
	position: relative;
	display: flex;
	padding-top: 30px;
    
    @include mq('sp') {
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    @include mq('pc') {
        padding: 60px 0 40px;
    }

    &--icon {
        @include mq('pc') {
            margin-right: 35px;
        }
    }

    &--details {
        @include mq('sp') {
            width: 48.6154%;
        }
    }

    &--detail_name {
        flex: 1;
        display: block;
		font-size: 2rem;
        font-weight: bold;
        
        @include mq('pc') {
            font-size: 4rem;
        }
    }

    &--detail_favorite {
        min-width: 5em;
        margin: 10px 0 0 1em;
        font-size: 1.8rem;
        color: $color-text-thin;

        @include mq('sp') {
            display: none;
        }

        &::before {
            content: '♡';
        }
    }

    &--detail_row {
        display: flex;
        justify-content: space-between;
    }

	&--detail_row-02{
		margin-top: 10px;
	}

    &--detail_genre {
        color: $color-text-thin;

        @include mq('sp') {
            font-size: 1rem;
        }

		img{
			width: auto;
            height: 11px;

            @include mq('pc') {
                height: 20px;
            }
		}
    }

	&--achieve_list{
		margin-top: 10px;
	}

	&--achieve_item{
		position: relative;
        display: block;
        margin-top: 5px;
		padding-left: 23px;
		font-size: 1rem;
		color: $color-key;
        
        @include mq('pc') {
            display: inline-block;
            margin: 0 15px 0 0;
            font-size: 1.6rem;
        }

		&::before{
			content: url('../img/common/icon_crown.svg');
			position: absolute;
			left: 0px;
		}
	}

	&--btn_area{
		flex: 2 0 auto;
		display: flex;
		margin-top: 25px;

        @include mq('sp') {
            position: relative;
        }
        
        @include mq('pc') {
            justify-content: flex-end;
            margin: 40px 0 0 10px;
        }
	}

	&--btn_wrap{
        margin-right: 10px;
        
        @include mq('pc') {
            margin-right: 30px;
            text-align: center;
        }
	}

    &--favorite_btn {
        &.is_bad {
            background-color: $color-bad;
            border-color: $color-bad;
            color: #FFF;
    
            &::before {
                background-image: url('../img/common/icon_bad_w.svg');
            }
            &::after{
                content: "イマイチ";
            }
    
            &.is_active {
                color: $color-bad;
                background-color: #fff;
                border-color: $color-bad;
    
                &::before {
                    background-image: url('../img/common/icon_bad.svg');
                }
                &::after{
                    content: "解除";
                }
            }
        }

        &.is_alarm {
            position: absolute;
            top: 0;
            right: 0;
            width: 38px;
            height: 37px;
            padding: 0;
            background-color: #FFF;
            border-color: $color-submit;
            color: $color-submit;
            
            @include mq('pc') {
                top: 40px;
                width: 59px;
                height: 41px;
            }
    
            &::before {
                right: 0;
                left: 0;
                width: 22px;
                height: 19px;
                background: url('../img/common/icon_alarm.svg') center center / contain no-repeat;
            }
            &::after{
                content: "";
            }
    
            &.is_active {
                color: #FFF;
                background-color: $color-submit;
                border-color: $color-submit;
    
                &::before {
                    background: url('../img/common/icon_alarm_w.svg') center center / contain no-repeat;
                }
            }
        }
    }

	&--btn_txt{
		display: block;
		color: $color-text-thin;
		font-size: 1rem;
        text-align: center;

        @include mq('pc') {
            display: none;
        }

		&::before {
            content: 'お気に入り数';
		}

		&::after {
            content: '人';
		}
	}
}
