.list {
    $this: &;
    position: relative;
    padding: 18px 0 250px;
    text-align: center;

    @include mq('sp') {
        background-color: #f4f4f4;
    }

    @include mq('pc') {
        padding-top: 58px;
    }

    &--checkbox {
        text-align: left;
        margin-bottom: 10px;

        @include mq('pc') {
            margin-bottom: 25px;
        }

        label {
            margin-left: 7px;
            color: #555;

            @include mq('sp') {
                font-size: 1.5rem;
            }

            &::before {
                content: url('../img/common/icon_beginner.svg');
                display: inline-block;
                margin-right: 5px;
                vertical-align: middle;
            }
        }
    }
    
    &--list {
        display: flex;
        flex-wrap: wrap;
    }
    
    &--item {
        transition: opacity 0.2s;
        
        @include mq('sp') {
            @include flex-column(3, true, 10px, 10px);
        }

        @include mq('pc') {
            @include flex-column(4, true, 95px, 50px);
        }

        &.is_hide {
            visibility: hidden;
            opacity: 0;
            height: 0;
            margin: 0;
        }
    }

    &--details {
        margin-top: 10px;

        @include mq('pc') {
            margin-top: 20px;
        }
    }

    &--detail_name {
        display: block;
        font-weight: bold;
        
        @include mq('sp') {
            font-size: 1rem;
        }
    }

    &--detail_favorite {
        display: block;
        color: $color-text-thin;

        @include mq('sp') {
            font-size: 1rem;
        }

        @include mq('pc') {
            margin-top: 5px;
        }

        &::before {
            content: 'いいね数 ';
        }

        &::after {
            content: '人';
        }
    }

    &--detail_now {
        @include mq('pc') {
            display: none;
        }
    }

    &--detail_genre {
        @include mq('pc') {
            display: none;
        }
    }

    &--favorite_btn {
        @include mq('sp') {
            margin-top: 5px;
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            font-size: 1rem;
            transition: none;

            &::before {
                content: none;
            }
        }
        
        @include mq('pc') {
            margin-top: 10px;
        }
    
    }

    //スマホグリッド表示
    &.is_grid {
        @include mq('sp') {
            #{$this}--item {
                position: relative;
            }

            #{$this}--details {
                margin: 0;
            }
    
            #{$this}--detail_name {
                display: none;
            }

            #{$this}--detail_favorite {
                display: none;
            }

            #{$this}--detail_genre {
                display: none;
            }

            #{$this}--detail_now {
                position: absolute;
                bottom: get_vw(8);
                left: get_vw(12);
                z-index: map-get($z-index, 'sp-grid-data');
                padding-left: get_vw(15);
                font-size: get_vw(13);
                color: #fff;
                
                &::before {
                    content: '';
                    position: absolute;
                    top: get_vw(3);
                    bottom: 0;
                    left: 0;
                    width: get_vw(10);
                    height: get_vw(7);
                    margin: auto;
                    background: url('../../img/common/icon_eye.svg') no-repeat border-box center/100% auto;
                }
            }

            #{$this}--favorite_btn {
                position: absolute;
                right: get_vw(12);
                bottom: get_vw(8);
                width: get_vw(19);
                height: get_vw(15);
                z-index: map-get($z-index, 'sp-grid-data');
                padding: 0;
                margin-top: 0;
                background-color: transparent;
                border: none;

                &::before {
                    content: '';
                    right : 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url('../../img/common/icon_favorite_transparent.svg');
                }

                &::after {
                    content: none;                    
                }

                &.is_active {
                    &::before {
                        background-image: url('../img/common/icon_favorite.svg');
                    }
                }
            }
        }
    }

    //スマホ詳細表示
    &.is_detail {
        @include mq('sp') {
            #{$this}--list {
                display: block;
            }

            #{$this}--item {
                display: flex;
                width: 100%;
                margin-top: 10px;
                margin-right: 0;
            }

            #{$this}--details {
                flex: 1;
                padding: 10px;
                margin: 0;
                background-color: #fff;
                border-radius: 3px;
            }

            #{$this}--detail_row {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                
                &-02 {
                    justify-content: flex-start;
                }
            }

            #{$this}--detail_name {
                font-size: 1.5rem;
                font-weight: bold;
            }

            #{$this}--detail_now {
                font-size: 1rem;
                color: $color-text-thin;

                &::after {
                    content: '人視聴中';
                }
            }

            #{$this}--detail_genre {
                width: 110px;
                font-size: 1rem;
                text-align: left;
                color: #afafaf;
                
                img {
                    height: 10px;
                    vertical-align: middle;
                }
            }
            
            #{$this}--detail_favorite {
                font-size: 1rem;
                color: #afafaf;

                &::before {
                    content: '♡';
                }
            }

            #{$this}--favorite_btn {
                display: block;
                width: 70px;
                padding: 0;
                margin-left: auto;
            }
        }
    }

    //内部ページ
    @include insert-context('.sec') {
        #{$this}--list {
            margin-top: 25px;
        }
    }

    @include insert-context('.sec', '.is_site') {
        padding-bottom: 0;
    }

    @include insert-context('.sec', '.is_recommend') {
        @include mq('sp') {
            padding-bottom: 215px;
        }
    }
}