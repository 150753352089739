@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese');

@import '~loaders.css/loaders.min.css';

.ball-beat>div, .ball-scale-multiple>div{
    background-color:#FF8686;
}
.ball-pulse-sync>div, .ball-pulse>div, .ball-scale>div{
    background-color:#FFF;
}
.ball-pulse-sync>div, .ball-pulse>div {
    height: 6px;
    width: 6px;
}
.ball-beat>div{
    height: 10px;
    width: 10px;
}
.loader_window{
    z-index:100001;
    position: fixed;
    top: 50%;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    text-align: center;
    background:rgba(0,0,0,0.3);
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
}
.pointer_events_none{
    pointer-events: none;
}
.conts--loading {
    position: absolute;
    right: 0;
    bottom: 150px;
    left: 0;
    display: block;
    margin: auto;
    text-align: center;
}

@import './base/variables';
@import './base/function';
@import './base/mixin';
@import './base/destyle';
@import './base/base';

@import './components/conts';
@import './components/favorite_btn';
@import './components/icon';
@import './components/inner';
@import './components/list';
@import './components/login';
@import './components/sec';
@import './components/tabs';
@import './components/tag';
@import './components/u';

@import './frame/main';
@import './frame/fnav';
@import './frame/footer';
@import './frame/gnav';
@import './frame/header';

@import './pages/_banner';
@import './pages/_character_head';
@import './pages/_character_profile';
@import './pages/_search';


