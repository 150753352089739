
.favorite_btn {
    position: relative;
    display: inline-block;
    height: 38px;
    padding: 0 15px 0 40px;
    font-size: 1.5rem;
    line-height: 38px;
    color: #fff;
    background-color: $color-key02;
    border: 1px solid $color-key02;
    border-radius: 2px;
    transition: all 0.2s ease;

    @include mq('pc') {
        padding: 0 15px 0 55px;
        height: 45px;
        font-size: 2rem;
        line-height: 45px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom : 0;
        left: 10px;
        width: 21px;
        height: 16px;
        margin: auto;
        background: url('../img/common/icon_favorite_w.svg') no-repeat border-box center/cover;

        @include mq('pc') {
            left: 15px;
            width: 28px;
            height: 22px;
        }
    }

    &::after{
        content: "お気に入り";
    }

    // &.is_login {
    //     background-color: $color-login;
    //     border-color: $color-login;
    // }

    // &.is_beginner {
    //     background-color: $color-beginner;
    //     border-color: $color-beginner;
    // }

    // &.is_undecided {
    //     background-color: $color-undecided;
    //     border-color: $color-undecided;
    // }

    &.is_active {
        color: $color-key02;
        background-color: #fff;
        border-color: $color-key02;

        &::before {
            background-image: url('../img/common/icon_favorite.svg');
        }
        &::after{
            content: "解除";
        }
    }
}
