.gnav {
    $this: &;
    position: fixed;
    top: map-get($headerH, 'sp');
    left: 0;
    transform: translateX(-100%);
    z-index: map-get($z-index, 'gnav');
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - #{map-get($headerH, 'sp')});
    padding: 0 25px 65px 0;
    background-color: #fff;
    transition: 0.4s;

    @include mq('tablet') {
        top: map-get($headerH, 'tab');
        width: 343px;
        height: calc(100% - #{map-get($headerH, 'tab')});
    }

    @include mq('pc') {
        top: map-get($headerH, 'pc');
        width: 343px;
        height: calc(100% - #{map-get($headerH, 'pc')});
    }

    &.is_active {
        transform: translateX(0);
    }

    &--unit {
        padding: 33px 0 20px 40px;

        &:not(:first-of-type) {
            border-top: 1px solid #C4C4C4;
        }
    }

    &--tit {
        font-size: 2rem;
        font-weight: bold;
    }

    &--list {
        margin-top: 10px;
    }

    &--item {
        a {
            display: flex;
            padding: 0.5em 0.6em 0.6em 0;
            font-size: 2rem;

            @include mq('tablet') {
                font-size: 2.4rem;
            }

            @include mq('pc') {
                font-size: 2.4rem;
            }
        }

        &.is_active {
            a {
                position: relative;
                color: $color-key;
                pointer-events: none;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -40px;
                    z-index: -1;
                    width: calc(100% + 80px);
                    height: 100%;
                    background-color: #EBEBEB;
                }
            }
        }
    }

    &--icon {
        position: relative;
        width: 31px;
        margin-right: 25px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right : 0;
            bottom : 0;
            left: 0;
        }

        @each $icon in $icons-gnav {
            &-#{$icon} {
                @extend #{$this}--icon;

                &::before {
                    background: url('../img/common/icon_#{$icon}.svg') no-repeat border-box center/auto;
                }
            }
        }

        @at-root #{$this}--item.is_active {
            @each $icon in $icons-gnav {
                #{$this}--icon-#{$icon} {
                    &::before {
                        background: url('../img/common/icon_#{$icon}_active.svg') no-repeat border-box center/auto;
                    }
                }
            }
        }

        @at-root #{$this}--item &-search,
        #{$this}--item.is_active &-search {
            &::before {
                background-size: auto 25px;
            }
        }
    }

    &--login {
        // margin-top: 25px;
    }
}
