// =====================================================================================
//
// character_profile
//
// =====================================================================================

.character_profile {
    &--inner {
        @include mq('pc') {
            display: flex;
            justify-content: space-between;
            padding-top: 50px;
        }
    }

    &--column {
        &.is_left {
            padding: 20px 0 25px;

            @include mq('pc') {
                width: 540px;
                padding: 0;
            }
        }
        
        &.is_right {
            padding: 20px 0 25px;
            background-color: #fff;
            
            @include mq('pc') {
                width: 474px;
                padding: 20px 0 35px;
            }
        }
    }

    &--img {
        @include mq('pc') {
            position: relative;
            overflow: hidden;
            height: 404px;
        }

        img {
            min-width: 100%;

            @include mq('pc') {
                position: absolute;
                top: 0;
                right : 0;
                bottom : 0;
                left: 0;
                margin: auto;
            }
        }
    }

    &--data {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        
        @include mq('pc') {
            padding: 0 20px;
        }

        dl {
            width: 43.077%;
            margin-top: 10px;
            border-bottom: 1px solid #c4c4c4;

            @include mq('pc') {
                margin-top: 15px;
            }

            &.is_w100 {
                width: 100%;
            }
        }

        dt {
            display: inline-block;
            width: 39.2857%;
            font-weight: bold;
            color: #555;
            
            @include mq('sp') {
                font-size: 1.2rem;
            }
            
            @include insert-context('dl', '.is_w100') {
                width: 16.9231%;
            }
        }
        
        dd {
            display: inline-block;
            font-size: 1.4rem;

            @include mq('pc') {
                font-size: 2rem;
            }
        }
    }

    &--txt {
        overflow-y: scroll;
        width: 88.6154%;
        max-height: 268px;
        margin: 0 auto;
        line-height: 1.6;
        
        @include mq('sp') {
            font-size: 1.5rem;
        }
        
        @include mq('pc') {
            max-height: 553px;
        }
    }
}