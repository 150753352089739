// =====================================================================================
//
// ベース
//  - 要素の基本スタイル
//
// =====================================================================================


html {
  font-size: 62.5%;
}

body {
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-family: $font-family;
  font-feature-settings: "palt" 1;
  font-size: $font-size-base;
  color: $color-text-base;
  word-break: break-all;

  @include mq('pc') {
    min-width: ($width-base-pc + (60 * 2));
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

a {
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
}

// 特定のブレイクポイントで非表示にするクラスをそれぞれ作成
// 例: .md-noneの場合ブレイクポイントがmd以上の場合は非表示
@each $prefix, $value in $media-queries {
  @media #{$value} {
  .#{$prefix}-none {
      display: none!important;
      }
  }
}