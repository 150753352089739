// =====================================================================================
//
// Sass変数
//
// =====================================================================================


// -------------------------------------------------------------------------------------
// カラー系
// -------------------------------------------------------------------------------------
$color-text-base : #000;
$color-text-link : #000;
$color-text-thin : #707070;
$color-key : #C7398F;
$color-key02 : #FF8686;
// $color-login : #FF7197;
// $color-undecided : #EA3F80;
$color-border : #DFDFDF;
$color-input : #000;
$color-submit : #4026AD;
$color-bad : #645366;
$color-party : linear-gradient(61deg,#FFFF30 0%,#FFFF30 18.5%,#FFD120 28.2%,#FFAA12 38.3%,#FF8D08 47.5%,#FF7C02 55.5%,#FF7600 61.4%,#FF7600 100%);
$color-active : linear-gradient(61deg,#FFDE30 0%,#FFDE30 33%,#F9DE2F 35.5%,#C6DE2F 60.4%,#B2DE2F 72.6%,#B2DE2F 100%);


// -------------------------------------------------------------------------------------
// テキスト系
// -------------------------------------------------------------------------------------
$line-height     : 1.5;
$letter-spacing  : 0.05rem;

$font-family    : "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
$font-size-base : 1.8rem;


// -------------------------------------------------------------------------------------
// 幅・サイズ
// -------------------------------------------------------------------------------------

// 基準となる幅
$width-base-sp     : 375px;
$width-base-tablet : 750px;
$width-base-pc     : 1116px;

// サイトの最大幅
$max-width: ($width-base-pc + (60 * 2));


// -------------------------------------------------------------------------------------
// メディアクエリ
// -------------------------------------------------------------------------------------

// 「base/_mixin.scss」にてmq()で使用
$media-queries: (
  'xs': 'screen and ( max-width: ' + $width-base-sp + ' )',
  'sp': 'screen and ( max-width: ' + $width-base-tablet + ' )',
  'tablet': 'screen and ( min-width: ' + ($width-base-tablet + 1px) + ' ) and ( max-width: ' + ($max-width - 1px) + ' )',
  'pc': 'screen and ( min-width: ' + ($width-base-tablet + 1px) ' )',
);


// -------------------------------------------------------------------------------------
// イージング
// -------------------------------------------------------------------------------------
$linear            : cubic-bezier(0.25, 0.25, 0.75, 0.75);

$ease-in-sine      : cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine     : cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine  : cubic-bezier(0.445, 0.05, 0.55, 0.95);

$ease-in-quad      : cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad     : cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad  : cubic-bezier(0.455, 0.03, 0.515, 0.955);

$ease-in-cubic     : cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic    : cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic : cubic-bezier(0.645, 0.045, 0.355, 1);

$ease-in-quart     : cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart    : cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart : cubic-bezier(0.77, 0, 0.175, 1);

$ease-in-quint     : cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint    : cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint : cubic-bezier(0.86, 0, 0.07, 1);

$ease-in-expo      : cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo     : cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo  : cubic-bezier(1, 0, 0, 1);

$ease-in-circ      : cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ     : cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ  : cubic-bezier(0.785, 0.135, 0.15, 0.86);

$ease-in-back      : cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back     : cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back  : cubic-bezier(0.68, -0.55, 0.265, 1.55);


// -------------------------------------------------------------------------------------
// ヘッダーの高さ
// -------------------------------------------------------------------------------------
$headerH: (
  'sp': 70px,
  'tab': 80px,
  'pc': 100px,
);


// -------------------------------------------------------------------------------------
// z-index管理
// -------------------------------------------------------------------------------------
$z-index: (
  'header': 1000,
  'gnav': 999,
  'sp-grid-data': 998,
  'sp-grid-shadow': 997,
);


// -------------------------------------------------------------------------------------
// グロナビアイコン
// -------------------------------------------------------------------------------------
$icons-gnav: 'home', 'search', 'event', 'chat', 'adult', 'noadult', 'lip', 'virtual';