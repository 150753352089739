// =====================================================================================
//
// 全ページ共通のサイトフッター
//
// =====================================================================================

.footer {
    position: relative;
    background-color: #EAEAEA;

    &--gotop {
        position: fixed;
        visibility: hidden;
        opacity: 0;
        right: 20px;
        bottom: 20px;
        transition: 0.4s;

        &.is-show {
            visibility: visible;
            opacity: 1;
        }

        @include mq('pc') {
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;
            width: 157px;
            margin: auto;
            text-align: center;
        }

        a {
            position: relative;
            line-height: 2;

            @include mq('sp') {
                display: block;
                width: 56px;
                height: 56px;
                font-size: 1.6rem;
                background-color: rgba(#000, 0.76);
                border-radius: 50%;

                &::before {
                    @include arrow('top', 12px, 2px, #fff);
                    content: '';
                    position: absolute;
                    top: 5px;
                    right : 0;
                    bottom : 0;
                    left: 0;
                    margin: auto;
                }
            }

            @include mq('tablet') {
                font-size: 1.6rem;
            }
        }

        @include mq('pc') {
            &::before, &::after {
                content: '';
                position: absolute;
                right: 0;
                left: 0;
                margin: auto;
                cursor: pointer;
            }
    
            &::before {
                top: -58px;
                width: 0;
                height: 0;
                margin: auto;
                border-style: solid;
                border-width: 0 78.5px 78px 78.5px;
                border-color: transparent transparent #fff transparent;
            }
            
            &::after {
                @include arrow('top', 16px, 3px, #808080);
                top: -20px;
            }
        }
    }

    &--inner {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;

        @include mq('tablet') {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 120px;
        }
        
        @include mq('pc') {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 160px;
        }
    }

    &--logo {
        display: inline-block;
    }

    &--copyright {
        margin-top: 1em;
        font-size: 1.2rem;

        @include mq('tablet') {
            font-size: 1.4rem;
        }

        @include mq('pc') {
            font-size: 1.4rem;
        }
    }
}