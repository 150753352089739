// =====================================================================================
//
// search
//
// =====================================================================================

.search{

	@include mq('sp') {
		display: none;
		background: #FFF;
		padding: 19px 15px;
		border-radius: 10px;
		margin-top: 20px;
	}

	&--box{
		border-top: 1px solid $color-border;

		&:first-of-type{
			border-top: none;
		}

		&-submit{
			padding-top: 15px;
			border-top: 1px solid $color-border;
			display: flex;
			justify-content: center;

			@include mq('sp') {
				flex-direction: column-reverse;
			}
		}
	}

	&--title{
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-right: 5px;
		padding: 20px 0 15px;
		font-size: 2.0rem;
		
		@include mq('sp') {
			font-size: 1.6rem;
			padding: 15px 0;

			&::after{
				content: "";
				position: absolute;
				right: 5px;
				top: 0;
				bottom: 0;
				width: 10px;
				height: 10px;
				margin: auto;
				border-top: 2px solid $color-text-thin;
				border-right: 2px solid $color-text-thin;
				transform: rotate(135deg);
			}
			
			&.is-active::after{
				transform: rotate(-45deg);
			}
		}

		span{
			display: block;
			text-align: right;
			flex: 2;
			margin-left: 20px;
			margin-right: 30px;
			font-size: 10px;
			font-weight: normal;
			color: $color-text-thin;

			@include mq('pc') {
				display: none;
			}
		}
	}

	&--data{
		margin-bottom: 30px;

		@include mq('sp') {
			display: none;
			margin-bottom: 20px;
		}
	}

	&--list{
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}

	&--item{
		width: auto;
		margin-top: 5px;
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		margin-right: 10px;
		
		@include mq('pc') {
			width: 33%;
			margin-right: 0;
		}
	}	

	label {
		cursor: pointer;
		padding-left: 25px;
		position: relative;
	  }
	  
	label::before,
	label::after {
	content: "";
	display: block; 
	position: absolute;
	}
	
	label::before {
	background-color: #FFF;
	border-radius: 3px;
	border: 2px solid #000;
	width: 15px;
	height: 15px;
	transform: translateY(-50%);
	top: 50%;
	left: 5px;
	}
	
	label::after {
	border-bottom: 2px solid #FFF;
	border-left: 2px solid #FFF;
	opacity: 0;
	height: 5px;
	width: 10px;
	transform: rotate(-45deg);
	top: 9px;
	left: 8px;
	}
	
	input:checked + label::before {
	background-color: $color-key;
	border: 2px solid $color-key;

	}

	input:checked + label::after {
	opacity: 1;
	}

	input[type="checkbox"]{
		margin-right: 10px;
		color: $color-input;
		display: none;

		&:checked{
			background-color: $color-key;

			&::before{
				background-color: $color-key;
			}
		}
	}

	input[type="text"]{
		border: 1px solid $color-input;
		width: 60%;
	}

	select{
		width: 150px;
		margin: 0 10px;
		position: relative;
		appearance: auto;
		border: 1px solid $color-input;
		border-radius: 3px;
		text-align: center;
	}

	&--list-tag{
		display: flex;
		flex-wrap: wrap;
		margin-left: -5px;
		margin-top: 5px;
		
		@include mq('pc') {
			margin-left: -20px;
		}
	}
	
	&--item-tag{
		margin-left: 5px;
		margin-bottom: 5px;
		
		@include mq('pc') {
			margin-left: 20px;
			margin-bottom: 20px;
		}

		label{
			padding: 0 10px;
			cursor: pointer;
			color: $color-key;
			border: solid 1px $color-key;
			border-radius: 5px;
		}

		input:checked + label{
			background-color: $color-key;
			color: #FFF;
		}
	  
		label::before,
		label::after {
		display: none; 
		}
	}

	&--submit{
		text-align: center;

		input[type="submit"]{
			padding: 5px 20px;
			border-radius: 5px;
			background-color: $color-submit;
			color: #FFF;
		}
	}
	
	&--back{
		color: $color-submit;
		text-align: center;
		
		@include mq('pc') {
			margin-right: 30px;
		}
	}
}

.result{
	margin-top: 100px;
	
	@include mq('sp') {
		margin-top: 30px;
	}
	
	&--title{
		border-bottom: 1px solid $color-border;
		line-height: 1.4;
		
		@include mq('pc') {
			margin-bottom: 50px;
		}

		span{
			font-size: 50px;
			margin-right: 5px;
		}
	}
}

.freeword{

	border-bottom: 1px solid $color-border;
	padding-top: 30px;
	
	&--search{
		display: flex;
		background-color: #FFF;
		padding: 5px;
	}

	img{
		margin-right: 10px;
	}

	input{
		width: 100%;
	}
}

.option_btn{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 30px 0 0 auto;
	font-size: 12px;
	line-height: 1;

	img{
		width: 18px;
		height: 16px;
		margin-right: 10px;
		margin-top: 3px;
	}
}