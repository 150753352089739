.icon {
    $this: &;
    display: flex;
    justify-content: center;
    align-items: center;
    height: get_vw(78);
    border-radius: 17%;
    
    @include mq('pc') {
        width: 100%;
        height: 159px;
    }

    &.is_pr {
        position: relative;

        &::before {
            @include mq('pc') {
                content: url('../img/common/icon_pr.png');
                position: absolute;
                right: 10px;
                bottom: -25px;
            }
        }
    }

    &.is_active {
        background: $color-active;
    }

    &.is_party {
        background: $color-party;
    }

    &--imgbox {
        box-sizing: content-box;
        position: relative;
        width: get_vw(98);
        height: get_vw(70);
        overflow: hidden;
        border: 3px solid #aaa;
        border-radius: 13%;
        
        @include mq('pc') {
            width: 192px;
            height: 144px;
            border-radius: 17%;
        }
    
        @include insert-context('#{$this}', '.is_pr') {
            &::after {
                @include mq('sp') {
                    content: url('../img/common/icon_pr.png');
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }

        @include insert-context('#{$this}', '.is_party') {
            border: none;
        }

        @include insert-context('#{$this}', '.is_active') {
            border: none;
        }

        img {
            position: absolute;
            top: 0;
            right : 0;
            bottom : 0;
            left: 0;
            width: 100%;
            margin: auto;
        }
    }

    //.list スマホグリッド表示
    @include insert-context('.list', '.is_grid') {
        @include mq('sp') {
            width: 100%;
            height: get_vw(87);
        }
        
        &--imgbox {
            @include mq('sp') {
                width: get_vw(100);
                height: get_vw(75);
            }

            &::before {
                @include mq('sp') {
                    content: '';
                    position: absolute;
                    top: 0;
                    right : 0;
                    bottom : 0;
                    left: 0;
                    z-index: map-get($z-index, 'sp-grid-shadow');
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent 45%);
                }
            }
        }
    }

    //.list スマホ詳細表示
    @include insert-context('.list', '.is_detail') {
        @include mq('sp') {
            width: get_vw(111);
            height: get_vw(87);
            margin-right: 10px;
        }

        &--imgbox {
            @include mq('sp') {
                width: get_vw(102);
                height: get_vw(75);
            }
        }
    }

    //個人ページ
    @include insert-context('.character_head') {
        flex: 0 0 auto;
        width: get_vw(158);
        height: get_vw(121);

        @include mq('pc') {
            width: 207px;
            height: 159px;
        }

        &--imgbox {
            @include mq('sp') {
                width: get_vw(147);
                height: get_vw(110);
            }
        }
    }
}
