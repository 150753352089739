// =====================================================================================
//
// 全ページ共通のサイトヘッダー
//
// =====================================================================================

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: map-get($z-index, 'header');
    width: 100%;
    background-color: #fff;

    &--inner-sp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: map-get($headerH, 'sp');
        padding: 0 20px;
        
        @include mq('tablet') {
            display: none;
        }
        @include mq('pc') {
            display: none;
        }
    }
    
    &--inner-pc {
        position: relative;
        justify-content: space-between;
        align-items: center;
        display: none;
        height: map-get($headerH, 'tab');
        padding: 0 50px;
        
        @include mq('tablet') {
            display: flex;
        }
        
        @include mq('pc') {
            display: flex;
            height: map-get($headerH, 'pc');
        }
    }

    &--unit {
        display: flex;
        align-items: center;

        @include mq('tablet') {
            min-width: 150px;
            justify-content: space-between;
        }

        @include mq('pc') {
            min-width: 233px;
        }
    }

    &--logo {
        @include mq('tablet') {
            width: 14vw;

            img {
                width: 100%;
            }
        }
    }

    &--menu_trigger {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 20px;
        padding: 3px;

        &:hover {
            opacity: 1;
        }

        @include mq('tablet') {
            margin-right: 15px;
        }

        @include mq('pc') {
            margin-right: 40px;
        }

        span {
            $paddingY: 0; //バーの間隔をここで調整
            position: absolute;
            left: 0;
            right: 0;
            display: inline-block;
            margin: auto;
            width: 100%;
            height: 3px;
            background-color: #5C5D5D;
            transition: all .4s;
    
            &:nth-of-type(1) {
                top: $paddingY; 
            }
    
            &:nth-of-type(2) {
                top: 0;
                bottom: 0;
            }
    
            &:nth-of-type(3) {
                bottom: $paddingY; 
            }
        }
    
        &.is_active {
            span {
                &:nth-of-type(1) {
                    top: 50%;
                    transform: translateY(-50%) rotate(-45deg);
                }
    
                &:nth-of-type(2) {
                    opacity: 0;
                }
    
                &:nth-of-type(3) {
                    bottom: 50%;
                    transform: translateY(50%) rotate(45deg);
                }
            }
        }
    }

    &--search {
        position: relative;
        border: 1px solid #C4C4C4;
        height: 50px;
        width: 38vw;
        
        input {
            width: 100%;
            height: 100%;
            padding: 3px 72px 0 1em;
            
            &::placeholder {
                font-size: 20px;
            }
        }
        
        @include mq('pc') {
            height: 62px;
            max-width: 730px;
        }
    }

    &--search_btn {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 30px;

        @include mq('tablet') {
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            width: 50px;
            height: 50px;
            margin: auto;
        }

        @include mq('pc') {
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            width: 62px;
            height: 62px;
            margin: auto;
        }

        img {
            position: absolute;
            top: 0;
            right : 0;
            bottom : 0;
            left: 0;
            margin: auto;
        }
    }

    &--option {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 50px;
        background-color: #F7F7F7;
        border-top: 1px solid #C4C4C4;
        border-right: 1px solid #C4C4C4;
        border-bottom: 1px solid #C4C4C4;
        
        @include mq('pc') {
            width: 62px;
            height: 62px;
        }

        img {
            position: absolute;
            top: 0;
            right : 0;
            bottom : 0;
            left: 0;
            margin: auto;
        }
    }
}