// =====================================================================================
//
// ユーティリティ（ヘルパー）クラス
//
// =====================================================================================

// -------------------------------------------------------------------------------------
// クリアフィックス
// -------------------------------------------------------------------------------------
.u-clearfix::after,
.u-cf::after {
  display: block;
  clear: both;
  content: "";
}