// =====================================================================================
//
// sec
//
// =====================================================================================

.sec {
    &--tit {
        padding-top: 5px;
        margin-top: 25px;
        font-size: 2rem;
        font-weight: normal;
        border-top: 3px solid $color-border;
        
        @include mq('pc') {
            padding-top: 50px;
            margin-top: 50px;
            font-size: 2.6rem;
            font-weight: bold;
        }
    }

    &--txt {
        margin-top: 10px;
        line-height: 1.7778;

        @include mq('sp') {
            font-size: 1.5rem;
        }

        @include mq('pc') {
            margin-top: 25px;
        }
    }

    &.is_dummy {
        padding: 1px;
    }
}