.inner {
    padding: 0 60px;
    margin: 0 auto;

    @include mq('sp') {
        width: 92%;
        max-width: 92%;
        min-width: 92%;
        padding: 0;
    }
    
    @include mq('tablet') {
        max-width: ($width-base-pc + (60 * 2));
		min-width: ($width-base-pc + (60 * 2));
	}

    @include mq('pc') {
        max-width: ($width-base-pc + (60 * 2));
		min-width: ($width-base-pc + (60 * 2));
	}
	
	&.is_spwide{
        @include mq('sp') {
            width: 100%;
            max-width: 100%;
            min-width: 100%;     
        }
	}

	&.is_sp{
        @include mq('pc') {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            padding: 0;   
        }
	}
}