.tag {
    $this: &;
    width: calc(100% + 10px);
    
    @include mq('pc') {
        width: calc(100% + 20px);
    }

    &--item {
        display: inline-block;
        margin: 10px 10px 0 0;
        
        @include mq('pc') {
            margin: 20px 20px 0 0;
        }

        a {   
            display: block;
            padding: 3px 10px;
            color: $color-key;
            border: 1px solid $color-key;
            border-radius: 4px;
        }

    }
    
    &-gnav {
        width: 100%;
        margin-top: 25px;

        #{$this}--item {
            display: block;
            margin: 20px 0 0 0;

            a {
                display: flex;
                justify-content: space-between;
                width: 100%;

                span {
                    color: $color-text-base;
                }
            }
        }
    }

    &.is_character {
        @include mq('pc') {
            width: calc(100% + 15px);
        }

        #{$this}--item {
            @include mq('pc') {
                margin: 15px 15px 0 0;
            }

            a {
                font-size: 1.5rem;
                color: #fff;
                background-color: $color-key;

                @include mq('pc') {
                    font-size: 1.8rem;
                }
            }
        }
    }
}